import { useNavigate } from "@solidjs/router";
import { createEffect, createSignal, Show } from "solid-js";
import { ButtonDark } from "~/components/gifting/verification/components";
import { OtpInput } from "~/components/login-rnr/login_form";
import { useModal } from "~/components/modal";
import { PhosphorIcon } from "~/widgets/icons";

export function AuthenticationModal(props: {
  giftingKey: string;
  fetchGiftBoxAndSetDataInLocalStorage: ({
    giftingKey,
    activationCode,
  }: {
    giftingKey: string;
    activationCode?: string;
  }) => Promise<void>;
  openActivationCodeInfoModal: () => void;
}) {
  const navigate = useNavigate();
  const [activationCode, setActivationCode] = createSignal<string | null>(null);
  const { closeModal } = useModal()!;
  const [error, setError] = createSignal<string | undefined>();
  const [isLoading, setIsLoading] = createSignal<boolean>(false);

  createEffect(() => {
    if (activationCode()) {
      setError(undefined);
    }
  });

  const authAndRedirect = async () => {
    setIsLoading(true);

    try {
      await props.fetchGiftBoxAndSetDataInLocalStorage({
        giftingKey: props.giftingKey,
        activationCode: activationCode() ?? undefined,
      });
      closeModal();
      navigate("/gift-box/m/" + props.giftingKey);
    } catch (e: any) {
      setActivationCode(null);
      setError("Please enter a valid code");
    }
    setIsLoading(false);
  };

  return (
    <div class="flex w-full flex-col rounded-t-3xl border border-basePrimaryMedium p-4">
      <div class="flex w-full justify-between">
        <div class="text-h3 text-textWhite">
          {"Please enter"} <br /> {" gift activation code"}
        </div>
        <div
          class="flex h-8 w-8 items-center justify-center rounded-full border  border-basePrimaryMedium bg-basePrimaryLight p-2 text-center"
          onClick={() => {
            closeModal();
          }}
        >
          {" "}
          <PhosphorIcon
            name="x"
            fontSize={16}
            size="bold"
            class="text-textWhite"
          />{" "}
        </div>
      </div>
      <div>
        <OtpInput
          handleOTPChange={(e) => {
            const value = (e.currentTarget as HTMLInputElement).value;
            setActivationCode(value.toUpperCase());
          }}
          isError={error() != undefined}
          otp={activationCode}
          setOTP={setActivationCode}
          removeFocus={error() != undefined}
          isTypeAlphaNumeric={true}
        />
      </div>

      <Show when={error()}>
        {<div class="my-1 text-medium text-errorDark">{error()}</div>}
      </Show>

      <div
        class="mb-4 mt-4 text-medium text-textNormal underline"
        onClick={props.openActivationCodeInfoModal}
      >
        I don’t have an activation code
      </div>
      <ButtonDark
        class="w-full !rounded-[32px] py-4"
        isLoading={isLoading}
        onClick={async () => {
          if (activationCode()?.length === 6) {
            await authAndRedirect();
          } else {
            setError("Enter valid activation code");
          }
        }}
        children={<div>Continue</div>}
      />
    </div>
  );
}
